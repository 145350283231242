import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Markdown from 'markdown-to-jsx';
import PartnerCard from '../business-perks/PartnerCard';

const styles = theme => ({
  controls: {
    paddingTop: '20px',
    display: 'flex',
    width: '100%'
  },
  leftArrow: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: theme.spacing(4)
  },
  rightArrow: {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: theme.spacing(4)
  },
  tabs: {
    minHeight: '36px',
    padding: 0,
    width:'100%',
    paddingTop: theme.spacing(1)
  },
  tabsFull: {
    minHeight: '36px',
    padding: 0,
    paddingTop: theme.spacing(3)
  },
  scroller: {
    height: 'fit-content'
  },
  tabsMobile: {
    minHeight: '10px',
    height: '10px',
    width: 'fit-content',
    padding: 0
  },
  tabsMobileRoot: {
    minHeight: '10px',
  },
  mobileContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tabsFullContainer: {
    display: 'flex',
    width: '100%'
  },
  tab: {
    padding: '0 12px',
    minWidth: 'fit-content',
    minHeight: '36px',
    color: '#6B708F',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '23.5px',
    '&:hover': {
      color: '#6B708F',
      backgroundColor: '#F9F8F7',
      borderRadius: '70px',
    }
  },
  content: {
    padding: '0 24px',
    margin: '0 12px',
    minHeight: '36px',
    color: '#6B708F',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '23.5px',
    '&:hover': {
      color: '#6B708F',
      backgroundColor: '#F9F8F7',
      borderRadius: '70px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      margin: '0',
    }
  },
  tabSelected: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '23.5px',
    backgroundColor: '#1160F7',
    borderRadius: '70px',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#1160F7',
      borderRadius: '70px',
    }
  },
  mobileTab: {
    backgroundColor: '#C4C4C4',
    minWidth: '10px',
    padding: 0,
    minHeight: '10px',
    borderRadius: '12px',
    margin: '0 6px'
  },
  mobileTabSelected: {
    pointerEvents: 'none',
    backgroundColor: '#1160F7',
    minWidth: '10px',
    padding: 0,
    minHeight: '10px',
    borderRadius: '12px',
    margin: '0 6px'
  },
  mobileTabSelectedSecondary: {
    pointerEvents: 'none',
    backgroundColor: '#fff',
    minWidth: '10px',
    padding: 0,
    minHeight: '10px',
    borderRadius: '12px',
    margin: '0 6px'
  },
  indicator: {
    height: 0,
    backgroundColor: 'transparent',
    display: 'none'
  },
  wrapped: {
    width: 'fit-content',
  },
  container: {
    fontFamily: 'Inter',
    paddingTop: theme.spacing(3),
    color: '#626680',
    fontSize: '18px',
    lineHeight: '34px',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '34px',
    }
  },
  hidden: {
    display: 'none',
    visibility: 'hidden',
    marginRight: '-100%',
    width: '100%',
    height: '100%'
  },
  display: {
    width: '100%',
    display:'block',
    visibility: 'visible'
  },
  tabsContainer: {
    display: 'flex',
    width: '100%'
  },
  arrow: {
    color: '#1160F7'
  },
  arrowSecondary: {
    color: '#fff'
  },
  centeredTabs:{
    display:'flex',
    justifyContent:'center !important',
  },
  tabPanelContainer:{
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "24px",
    flexWrap:'wrap',
    justifyContent:'space-evenly',
    marginBottom:'8px'
  }
});

const useStyles = makeStyles(styles)



const TabsSection = ({ data, slider, fullWidth, variant, contentType, partners, hideArrows, activeTab, content, handleChangeTab}) => {
  const classes = useStyles();
  const [value, setValue] = useState(activeTab || 0);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChange = (event = null, newValue) => { // eslint-disable-line no-unused-vars
    setValue(newValue);
    if (handleChangeTab) handleChangeTab(newValue);
  };

  const handlePrevious = () => {
    const current = value;
    setValue(current - 1);
  };

  const handleNext = () => {
    const current = value;
    setValue(current + 1);
  };

  const TabPanel = props => {
    const { children, value, index, ...other } = props;
  
    return (
      <div {...other} className={value !== index ? classes.hidden : classes.display}>
        <div className={classes.container}>
          {children}
        </div>
      </div>
    );
  }

  return (
    <>
      {(isTablet || contentType === 'cards' || content) && !slider && 
      <Tabs value={value} onChange={handleChange} classes={{ root: fullWidth ? classes.tabsFull : classes.tabs, indicator: classes.indicator,flexContainer: content ? classes.centeredTabs : contentType === "cards" && !isMobile ?  classes.centeredTabs : ''}}>
        {data && data.map((tab, index) => (
          <Tab key={index} label={tab.title} classes={{ root: classes.tab, selected: classes.tabSelected, wrapped: classes.wrapped,flexContainer:contentType === 'cards' && !isMobile && classes.centeredTabs }} />
        ))}
        {content && content.map((tab, index) => (
          <Tab key={index} label={tab.label} classes={{ root: classes.content, selected: classes.tabSelected, wrapped: classes.wrapped,flexContainer: classes.centeredTabs }} />
        ))}
      </Tabs>}
      {data && data.length > 0 && (
        <div className={fullWidth ? classes.tabsFullContainer : classes.tabsContainer}>
          {!partners ? data.map((tab, index) => 
              <TabPanel value={value} index={index} key={index+10} >
                {tab && tab.text && typeof (tab.text) === 'string' ? <Markdown children={tab.text} /> : tab}
              </TabPanel>
            ) : data.map((tab,index) =>
              <TabPanel value={value} index={index} key={index+10} >
                <div className={classes.tabPanelContainer}> 
                {partners.filter((partner) => data[value].title === partner.category).map((partner) => <PartnerCard partner={partner} />)}
                </div>
              </TabPanel>
            )
          }
        </div>
      )}
      {content && content.length > 0 && content.map((tab, index) => (
          <TabPanel value={value} key={index} index={index}>
              {tab.panel}
          </TabPanel>
      ))}
      {(contentType !== 'cards' && !isTablet || slider) && (
        <Box className={classes.controls} display="flex" justifyContent="center">
          {!hideArrows && <Box className={classes.leftArrow}>
            <IconButton className={variant === 'secondary' ? classes.arrowSecondary : classes.arrow} onClick={handlePrevious} disabled={value === 0} aria-label="Back">
              <ArrowBackIcon />
            </IconButton>
          </Box>}
          <Box>
            <div className={classes.mobileContainer}>
              <Tabs
                centered
                value={value}
                onChange={handleChange}
                classes={{ root: classes.tabsMobileRoot, centered: classes.tabsMobile, indicator: classes.indicator }}>
                {data && data.map((tab, index) => (
                  <Tab aria-label={`Slide ${index + 1}`} key={index + 20} classes={{ root: classes.mobileTab, selected: variant === 'secondary' ? classes.mobileTabSelectedSecondary : classes.mobileTabSelected }} />
                ))}
              </Tabs>
            </div>
          </Box>
          {!hideArrows && <Box className={classes.rightArrow}>
            <IconButton className={variant === 'secondary' ? classes.arrowSecondary : classes.arrow}  onClick={handleNext} disabled={value === data.length - 1} aria-label="Next">
              <ArrowForwardIcon />
            </IconButton>
          </Box>}
        </Box>
      )}
    </>
  )
}

export default TabsSection;
