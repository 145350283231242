import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';
import rbTheme from '../../../../remitbee/theme/Theme';

const styles = makeStyles(theme => ({
  blue: {
    background: '#1160F7',
    fontSize: '18px',
    fontWeight: '500',
    fontFamily: 'Inter',
    color: '#ffffff',
    border: 'none',
    borderRadius: '8px',
    padding: '10px 40px',
    transition: "ease-in-out 0.3s",
    [theme?.breakpoints.down('sm')]: {
      fontSize: "16px",
    },
    minWidth: '200px',
    '&:hover': {
      background: '#1160F7',
      color: '#ffffff',
      boxShadow: '3px 8px 24px rgba(55,56,60,.35)',
      transform: 'translateY(-2px)',
    },
  },
  white: {
    background: '#ffffff',
    fontSize: '18px',
    fontWeight: '500',
    fontFamily: 'Inter',
    color: '#1160F7',
    border: 'none',
    borderRadius: '3px',
    transition: "ease-in-out 0.3s",
    padding: '10px 40px',
    minWidth: '200px',
    '&:hover': {
      background: '#ffffff',
      color: '#1160F7',
      boxShadow: '3px 8px 24px rgba(55,56,60,.35)',
      transform: 'translateY(-2px)',
    },
  },
  green: {
    background: '#2EBC31',
    fontSize: '18px',
    fontWeight: '500',
    fontFamily: 'Inter',
    color: '#ffffff',
    border: 'none',
    borderRadius: '3px',
    transition: "ease-in-out 0.3s",
    padding: '10px 40px',
    minWidth: '200px',
    '&:hover': {
      background: '#2EBC31',
      color: '#ffffff',
      boxShadow: '3px 8px 24px rgba(55,56,60,.35)',
      transform: 'translateY(-2px)',
    },
  },
  outlinedBlue: {
    height: '48px',
    background: 'transparent',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
    color: '#1160F7',
    border: '1px solid #1160F7',
    borderRadius: '8px',
    transition: "ease-in-out 0.3s",
    padding: '10px 40px',
    minWidth: '200px',
    [theme?.breakpoints.down('sm')]: {
      fontSize: "16px",
      width:'100%'
    },
    '&:hover': {
      background: '#1160F7',
      color: '#ffffff',
    },
  },
  outlinedWhite: {
    background: 'transparent',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    fontFamily: 'Inter',
    border: '1px solid #ffffff',
    borderRadius: '3px',
    transition: "ease-in-out 0.3s",
    padding: '10px 40px',
    minWidth: '200px',
    '&:hover': {
      background: '#ffffff',
      color: '#1160F7',
    },
  },
  linkBlueBtn: {
    color: '#1160F7',
    background: 'transparent',
    transition: "ease-in-out 0.3s",
    fontSize: '18px',
    padding: '0 !important',
    fontFamily: 'Inter',
    marginTop: '30px',
    textDecoration: 'none',
    "&:after": {
      opacity: '.2',
      position: 'absolute',
      bottom: '2px',
      left: '0',
      content: "''",
      display: 'block',
      width: '100%',
      height: '2px',
      background: '#1160F7',
      transition: 'opacity .2s ease-in-out',
    },
    "&:hover": {
      background: 'transparent',
      "&:after": {
        opacity: '1',
      },
    },
  },
  boldLinkBlueBtn: {
    color: '#1160F7',
    background: 'transparent',
    transition: "ease-in-out 0.3s",
    fontFamily: 'Inter',
    fontSize: '18px',
    padding: '0 !important',
    marginTop: '30px',
    textDecoration: 'none',
    fontWeight: "bold"
  },
  action: {
    [theme?.breakpoints.up('sm')]: {
      fontSize: '18px',
      height: '56px',
      padding: '15px 64px'
    },
    [theme?.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme?.breakpoints.down('sm')]: {
      height: '48px',
      padding: '12px 48px !important'
    }
  }
}))

function CustomButton({ type, children, className, action, ...rest }) {
  const classes = styles();
  const isMobile = useMediaQuery(`(max-width: ${rbTheme.breakpoints.sm}px)`);
  return (
    <Button disableRipple fullWidth={isMobile} className={clsx(classes[type], className, action ? classes.action : '')} {...rest}>
      {type == "boldLinkBlueBtn" ? children + ">" : children}
    </Button>
  )
}

export default CustomButton;