import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@material-ui/core';
import { interpolate, parseArrFromString } from '../../../../shared/utility';
import Skeleton from '@material-ui/lab/Skeleton';
import Markdown from 'markdown-to-jsx';
import { setRedirectSignupCookie } from '../../../../shared/cookie-handler';
import theme from '../../../../remitbee/theme/Theme';
import classes from './PromotionalHero.module.scss';
import Container from '../../../../remitbee/components/container/Container';
import Typography from '../../../../remitbee/components/typography/Typography';
import Button from '../../../../remitbee/components/button/Button';
import Box from '../../../../remitbee/components/box/Box';
import { fetchCountriesMulticurrencyRest } from '../../../../redux/system/actions';
import Flag from '../../../../remitbee/components/flag/Flag';
import Image from '../../../../remitbee/components/image/Image';

const PromotionalHero = ({
    format,
    selectedCountry,
    selectedCurrency,
    tag,
    topChip,
    topChipIcon,
    chipList,
    title,
    subtitle,
    actionText,
    actionType,
    actionUrl,
    desktopImage,
    mobileImage,
    imageAlt,
    isMobileServer,
    imageWidth,
    imageHeight,
}) => {
    // const classes = useStyles();
    const router = useRouter();
    const isMobile = isMobileServer || useMediaQuery(`(max-width: ${theme?.breakpoints?.sm}px)`);
    const [listItems] = useState(chipList ? parseArrFromString(chipList) : null);
    const [specialRate, setSpecialRate] = useState({});
    const [finalSubtitle, setFinalSubtitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const fetchPromotionalRates = async () => {
            const rates = await fetchCountriesMulticurrencyRest();

            if (rates && rates.length) {
                const selectedCountryRateList = rates.filter(r => r && r.country_id === +selectedCountry);
                let selectedCountryRate = null;
                if (selectedCountryRateList && selectedCountryRateList.length) {
                    selectedCountryRate = selectedCountryRateList.find(r => r && r.currency_code === selectedCurrency) || selectedCountryRateList[0];
                }
                if (selectedCountryRate && Object.keys(selectedCountryRate).length) {
                    setSpecialRate({
                        ...selectedCountryRate,
                        special_rate: selectedCountryRate.special_rate ? (selectedCountryRate.special_rate?.includes('.') ? selectedCountryRate.special_rate : parseFloat(selectedCountryRate.special_rate).toFixed(2)) : selectedCountryRate.rate,
                        special_rate_adjustment: selectedCountryRate.special_rate_adjustment?.includes('.') ? selectedCountryRate.special_rate_adjustment : parseFloat(selectedCountryRate.special_rate_adjustment).toFixed(2),
                        special_rate_transfer_amount_limit: selectedCountryRate.special_rate_transfer_amount_limit?.includes('.') ? selectedCountryRate.special_rate_transfer_amount_limit : parseFloat(selectedCountryRate.special_rate_transfer_amount_limit).toFixed(2),
                    });
                    const final = interpolate(subtitle, selectedCountryRate);
                    setFinalSubtitle(final);
                }
                setLoading(false);
            } else setLoading(false);
        };

        if (selectedCountry) fetchPromotionalRates();

    }, [selectedCountry])

    const handleButtonClick = async () => {
        if (actionUrl && actionUrl.includes('signup')) {
            await setRedirectSignupCookie(router.asPath)
        }
        if (actionUrl) router.push(actionUrl);
    }

    const promotionalRate = (variant, small) => {
        const { special_rate, currency_code, iso2, rate } = specialRate;

        return (
            <Box display={'flex'} alignItems='center' justifyContent='center' mt={small ? 0 : 3}>
                <div>
                    <Typography variant={'text2XL'} className={classes['rb-currency']} weight='semibold' align='center' style={variant ? { color: variant } : null}>
                        <Flag className={classes['rb-promotional-flag']} code="CA" />
                        {`1.00`}
                        <span style={{ fontSize: isMobile ? '14px' : '18px' }}>CAD</span>
                    </Typography>
                </div>

                <img width={small ? '35px' : '58px'} className={classes['rb-arrow']} src='/new/rate-arrow.svg' alt='Arrow' />

                <div>
                    {loading ? (
                        <Box display='flex' gridColumnGap='8px' alignItems='center'>
                            <Skeleton variant="rect" height={15} className={small ? classes.flagIconSmall : classes.flagIcon} />
                            <Skeleton variant="rect" width={90} height={24} />
                        </Box>
                    ) : (
                        <Typography variant={'text2XL'} className={classes['rb-currency']} weight='semibold' align='center' style={variant ? { color: variant } : null}>
                            <Flag className={classes['rb-promotional-flag']} code={iso2} />
                            {special_rate ? special_rate : rate}
                            <span style={{ fontSize: isMobile ? '14px' : '18px' }}>{currency_code}</span>
                        </Typography>
                    )}
                </div>
            </Box>
        )
    }

    // if (format === 'largeImage') return (
    //     <Grid className={classes.container}>
    //         {tag && <Typography align='center' className={classes.chip}>
    //             <span className={classes.tag}>{tag}</span>
    //         </Typography>}
    //         {title && <Typography variant='h1' align='center' className={classes.title}>{title}</Typography>}
    //         {finalSubtitle && <Typography variant='h2' align='center' className={classes.subtitle}>
    //             <Markdown>{finalSubtitle}</Markdown>
    //         </Typography>}

    //         {specialRate && promotionalRate()}

    //         {actionText && actionUrl && <div className={classes.button}>
    //             <Button type={actionType} onClick={handleButtonClick}>{actionText}</Button>
    //         </div>}
    //         {!isMobile && desktopImage && (
    //             <div className={classes.imageContainer}>
    //                 <img src={`${desktopImage}?${new Date().getTime()}`} alt={imageAlt} className={classes.image} />
    //             </div>
    //         )}
    //         {isMobile && mobileImage && (
    //             <div className={classes.imageContainer}>
    //                 <img src={mobileImage} alt={imageAlt} className={classes.image} />
    //             </div>
    //         )}
    //     </Grid>
    // )

    // if (format === 'blueCard') return (
    //     <Grid container className={classes.container}>
    //         <Grid item className={classes.blueCard}>
    //             {topChip && <Box display='flex' justifyContent='center'>
    //                 <span className={classes.topChip}>
    //                     {topChipIcon && <img src={topChipIcon} alt='Chip icon' />}
    //                     {topChip}
    //                 </span>
    //             </Box>}

    //             {title && <Typography variant='h1' align='center' className={classes.title} style={{ color: '#fff' }}>{title}</Typography>}

    //             {finalSubtitle && <Typography variant='h2' align='center' className={classes.subtitle} style={{ color: '#fff' }}>
    //                 <Markdown>{finalSubtitle}</Markdown>
    //             </Typography>}

    //             {specialRate && promotionalRate('white')}

    //             {actionText && actionUrl && <div className={classes.button}>
    //                 <Button type={actionType} onClick={handleButtonClick}>{actionText}</Button>
    //             </div>}

    //             {listItems && <Box className={classes.chipList} display='flex' flexWrap="wrap">
    //                 {listItems.map((item, index) => (
    //                     <span className={classes.topChip} style={{ borderRadius: 200 }} key={index}>
    //                         {item.icon && <img src={item.icon} alt='Chip icon' />}
    //                         {item.text}
    //                     </span>
    //                 ))}
    //             </Box>}
    //         </Grid>
    //     </Grid>
    // )

    return (
        <Container type='large' className={classes['rb-promotionalHero-container']}>
            <Grid container>
                <Grid item container className={classes['rb-whiteCard']}>
                    <Grid item xs={12} md={6}>
                        {topChip && <Box display='flex' justifyContent={isMobile ? 'center' : 'left'}>
                            <div className={classes['rb-chip']}>
                                <img src={topChipIcon} alt='Chip icon' />
                                <Typography variant='textSm' color={theme.palette.primary.blue} weight='semibold'>{topChip}</Typography>
                            </div>
                        </Box>
                        }

                        {title && <Typography variant='displayXl' weight='bold' align={isMobile ? 'center' : 'left'} color={theme.palette.primary.navy}>{title}</Typography>}

                        {finalSubtitle !== '' && <Box pt={4}>
                            <Typography variant='textXl' align={isMobile ? 'center' : 'left'}>
                                <Markdown>{finalSubtitle}</Markdown>
                            </Typography>
                        </Box>}

                        {finalSubtitle === '' && (
                            // <Box className={classes.subtitleSkeleton}>
                            //     {Array.from(Array(4)).map((item, index) => (
                            //         <Skeleton variant='rect' height={27.9} key={index} />
                            //     ))}
                            // </Box>
                            <Box pt={4}>
                               {subtitle.includes('special_rate')? <Typography variant='textXl' align={isMobile ? 'center' : 'left'}>
                                    <Markdown>{subtitle.split('1 CAD')[0]}</Markdown> <Skeleton width={200} height={30} />
                                </Typography>: <Typography variant='textXl' align={isMobile ? 'center' : 'left'}>
                                    <Markdown>{subtitle}</Markdown>
                                </Typography>}
                            </Box>
                        )}

                        <Box display='flex' justifyContent={isMobile ? 'center' : 'flex-start'} pt={isMobile ? 6 : 10}>
                            <div className={classes['rb-blueRate']}>
                                {specialRate && promotionalRate('white', true)}
                            </div>
                        </Box>

                        {actionText && actionUrl && <>
                            <Box pt={10} pb={isMobile ? 0 : 10} display='flex' justifyContent={isMobile ? 'center' : 'flex-start'}>
                                <Button variant='filledPrimary' onClick={handleButtonClick} className={classes['rb-promotional-button']}>{actionText}</Button>
                            </Box>
                        </>}
                    </Grid>
                    <Grid item xs={12} md={6} style={{ position: 'relative' }}>
                        {listItems && imageLoaded && <Box
                            className={classes['rb-blueChipList']}
                            display='flex'
                            flexWrap='wrap'
                            flexDirection='column'
                            alignItems='flex-end'>
                            {listItems.map((item, index) => (
                                <span className={classes['rb-blueTopChip']} key={index}>
                                    {item.icon && <img src={item.icon} alt='Chip icon' />}
                                    <Typography
                                        variant='textMd'
                                        weight='medium'
                                        color={theme.palette.background.white}>{item.text?.toUpperCase()}</Typography>
                                </span>
                            ))}
                        </Box>}
                        {desktopImage && (
                            <Box
                                display='flex'
                                alignItems='flex-end'
                                justifyContent='flex-end'
                            >
                                <Box
                                    className={classes['rb-promotional-image']}
                                    style={{ maxWidth: `${imageWidth}px` }}
                                >
                                    <Image
                                        url={desktopImage}
                                        alt={imageAlt}
                                        loading='lazy'
                                        onLoad={() => setImageLoaded(true)}
                                        width={imageWidth}
                                        height={imageHeight}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}


export default PromotionalHero;
